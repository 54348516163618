import "./App.css";
import React from "react";

import Header from "./components/Header";
import PageContainer from "./components/PageContainer";
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";
import { Route, Routes } from "react-router-dom";

export const LangContext = React.createContext();

function App() {
  return (
    <Routes>
      <Route
        path="/eng"
        element={
          <LangContext.Provider value="eng">
            <Header />
            <PageContainer>
              <Main />
              <Sidebar />
            </PageContainer>
          </LangContext.Provider>
        }
      ></Route>
      <Route
        path="/tr"
        element={
          <LangContext.Provider value="tr">
            <Header />
            <PageContainer>
              <Main />
              <Sidebar />
            </PageContainer>
          </LangContext.Provider>
        }
      ></Route>
      <Route path="*"></Route>
    </Routes>
  );
}

export default App;
