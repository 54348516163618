export const WorkExperience = ({ company, comments }) => {
  return (
    <div>
      <h3>
        <a style={{ color: "black" }} href={company.href}>
          {company.name}
        </a>
      </h3>
      <ul style={{ margin: "0", padding: "0 1rem" }}>
        {comments.map((com) => (
          <li key={com}>{com}</li>
        ))}
      </ul>
    </div>
  );
};
