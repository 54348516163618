import React from "react";

const headerStyle = {
  backgroundColor: "#353C46",
  color: "white",
  padding: "1rem 2rem",
  flexGrow: 0,
};

export default function Header() {
  return (
    <div style={headerStyle}>
      <h1>Cavit Ertuğrul Sırt</h1>
      <p>Full Stack Developer</p>
    </div>
  );
}
