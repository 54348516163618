import React from "react";

const pageStyle = {
	display: "grid",
	gridTemplateColumns: "70% 30%",
	flexGrow: 1,
};

export default function PageContainer({ children }) {
	return <div style={pageStyle}>{children}</div>;
}
