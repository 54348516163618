import React, { useContext } from "react";
import { LangContext } from "../App";

const sidebarStyle = {
  margin: "0 0",
  borderLeft: "1px solid #353C46",
  padding: "1rem",
};

const HEADERS = {
  "Personal Info": "Bireysel",
  Address: "Adres",
  Phone: "Telefon",
  Skills: "Beceriler",
  Languages: "Yabancı dil",
};

export default function Sidebar() {
  const lang = useContext(LangContext);

  return (
    <div style={sidebarStyle}>
      <SideSection header={getHeader("Personal Info", lang)}>
        <Info header={getHeader("Phone", lang)} body="(+90) 531-345-77-32" />
        <Info header="Email" body="ertugrulsirtt@gmail.com" />
        <Info header="Github" body="github.com/ertucode" link={true} />
        <Info
          header="LinkedIn"
          body="linkedin.com/in/cavitertugrulsirt/"
          link={true}
        />
      </SideSection>
      <SideSection header={getHeader("Language", lang)}>
        <LanguageInfo
          label="English"
          desc="Written: Advanced, Conversation: Needs Practice"
        />
        <LanguageInfo label="Turkish" desc="Native" />
      </SideSection>
    </div>
  );
}

const LanguageInfo = ({ label, desc }) => {
  return (
    <div style={{ fontSize: "12px", marginBottom: "1rem" }}>
      <span style={{ fontWeight: "700" }}>{label}: </span>
      <span>{desc}</span>
    </div>
  );
};

const infoStyles = {
  display: "flex",
  flexDirection: "column",
  gap: ".4rem",
  marginBottom: "1.3rem",
};

function Info({ header, body, link }) {
  return (
    <div style={infoStyles}>
      <h4 style={{ fontSize: ".7rem", marginBlock: "0" }}>{header}</h4>
      <div>{link ? <A link={body} /> : <P>{body}</P>}</div>
    </div>
  );
}

const sideSectionStyle = {
  paddingBottom: "1rem",
};

function SideSection({ header, children }) {
  return (
    <div style={sideSectionStyle}>
      <H3>{header}</H3>
      {children}
    </div>
  );
}

function H3({ children }) {
  return (
    <h3 style={{ fontSize: ".9rem", textDecoration: "underline" }}>
      {children}
    </h3>
  );
}

function P({ children }) {
  return <p style={{ fontSize: ".7rem", marginBlock: 0 }}>{children}</p>;
}

function A({ link }) {
  return (
    <a
      style={{ fontSize: ".7rem", display: "block" }}
      href={"https://www." + link}
    >
      {link}
    </a>
  );
}

function getHeader(key, lang) {
  if (lang === "eng") return key;
  else return HEADERS[key];
}
