import React, { useContext } from "react";
import { LangContext } from "../App";
import { Skills } from "./Skills";
import { WorkExperience } from "./WorkExperience";
import "./anchor.css";

const mainStyles = {
  padding: "1rem",
};

const COLLEGE = {
  tr: "Orta Doğu Teknik Üniversitesi - Makine Mühendisliği",
  eng: "Middle East Technical University - Mechanical Engineering",
};

const HEADERS = {
  "Completed Courses": "Tamamlanan Kurslar",
  Projects: "Projeler",
  Education: "Eğitim",
};

const skills = {
  "Extremely Confident": ["Angular", "Typescript", "CSS (SCSS)", "HTML", ".NET Core", "MS SQL", "EF Core"],
  Confident: [
    "React",
    "Prisma",
    "Svelte",
    "Next",
    "Tailwind",
    "C#",
    "Git",
    "CI/CD",
    "OpenID & OAuth",
    "Redis"
  ],
  "Worked With": ["MongoDB", "Python", "Lua", "Rust", "Fastify", "C++"],
};

export default function Main() {
  const lang = useContext(LangContext);

  return (
    <div style={mainStyles}>
      <MainSection header="Work Experience">
        <WorkExperience
          company={{
            name: "Sipay (2022 - ..)",
            href: "https://sipay.com.tr/",
          }}
          comments={[
            "Developed and solely maintained multiple frontend applications in Angular",
            "Worked with a team on a monolithic C# codebase. Improved code quality and was trusted to fix some long lasting problems",
            "Developed microservices to integrate with external services",
            "Developed stored procedures to feed dashboard charts",
          ]}
        />
      </MainSection>
      <MainSection header="Programming Knowledge And Abilities">
        <Skills skills={skills} />
      </MainSection>
      <MainSection header={getHeader("Education", lang)}>
        <Education
          years="2010-2014"
          body="İstanbul Köy Hizmetleri Anadolu Lisesi"
        />
        <Education years="2014-2022" body={COLLEGE[lang]} />
      </MainSection>
    </div>
  );
}

const mainSectionStyle = {
  paddingBottom: "1.5rem",
};

function MainSection({ header, children }) {
  return (
    <div style={mainSectionStyle}>
      <h2 style={{ borderBottom: "1px solid rgb(188, 203, 224)" }}>{header}</h2>
      {children}
    </div>
  );
}

function Education({ years, body }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: ".5rem",
        fontSize: "0.8rem",
      }}
    >
      <div style={{ fontWeight: 700 }}>{years}</div>
      <div>{body}</div>
    </div>
  );
}

function getHeader(key, lang) {
  if (lang === "eng") return key;
  else return HEADERS[key];
}
