import "./skills.css";

export const Skills = ({ skills }) => {
  return (
    <div>
      {Object.entries(skills).map(([header, skills]) => (
        <div key={header}>
          <h3 style={{ marginBottom: "8px" }}>{header}</h3>
          <div>
            {skills.map((s) => (
              <span key={s} className="p-skill">
                {s}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
